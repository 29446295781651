import useToast from '@hooks/useToast'
import EnvelopeIcon from '@public/icons/envelope.svg'
import PlaneIcon from '@public/icons/plane.svg'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { resetPassword } from '@lib/auth0'

import { ToastTypes } from '@models/toast'

import { TextField } from '@components/forms'
import { StyledButton } from '@components/shared'
import { StyledSvgLoader } from '@components/shared/SvgLoader/styles'

import { AccessMode } from './AccessPage'
import { messages } from './messages'
import {
  StyledPasswordResetForm,
  StyledPasswordResetButtons,
  StyledPasswordResetButton,
} from './styles'

export const ForgotPasswordComponent: React.FC<{
  setAccessMode: (accessMode: AccessMode) => void
}> = ({ setAccessMode }) => {
  const intl = useIntl()
  const { addToast } = useToast()
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const { control, handleSubmit } = useForm({
    mode: 'onTouched',
    defaultValues: { email: '' },
  })

  function onFormSubmit(formData: { email: string }, event?: React.BaseSyntheticEvent) {
    event?.preventDefault()
    setIsSubmitted(true)
    resetPassword(formData.email)
      .then(() => {
        setEmailSent(true)
      })
      .catch((error) => {
        addToast({
          message: error,
          type: ToastTypes.ERROR,
          noAutoClose: true,
        })
      })
      .finally(() => {
        setIsSubmitted(false)
      })
  }

  return (
    <StyledPasswordResetForm
      onSubmit={handleSubmit(onFormSubmit)}
      name="marketplace-forgot-password"
    >
      <h2>
        {intl.formatMessage(
          emailSent ? messages.forgotPasswordSentTitle : messages.forgotPasswordTitle
        )}
      </h2>
      <p>
        {intl.formatMessage(
          emailSent ? messages.forgotPasswordParagraph : messages.forgotPasswordSentParagraph
        )}
      </p>

      <Controller
        control={control}
        name="email"
        rules={{
          required: intl.formatMessage(messages.fieldRequiredError),
          pattern: {
            value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
            message: intl.formatMessage(messages.invalidMailError),
          },
        }}
        render={({ field, fieldState }) => {
          return (
            <TextField
              label={intl.formatMessage(messages.emailLabel)}
              type="email"
              id="email"
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              placeholder="your_name@email.com"
              icon={EnvelopeIcon}
              error={fieldState?.error?.message}
              required
            />
          )
        }}
      />

      <StyledPasswordResetButtons emailSent={emailSent}>
        {!emailSent && (
          <StyledButton onClick={() => setAccessMode(AccessMode.SignIn)}>
            {intl.formatMessage(messages.forgotPasswordBackButton)}
          </StyledButton>
        )}
        <StyledPasswordResetButton type="submit" disabled={isSubmitted}>
          {intl.formatMessage(
            emailSent
              ? messages.forgotPasswordSubmittedButtonText
              : messages.forgotPasswordButtonText
          )}
          <StyledSvgLoader {...PlaneIcon} width={48} />
        </StyledPasswordResetButton>
      </StyledPasswordResetButtons>
    </StyledPasswordResetForm>
  )
}
