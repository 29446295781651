import useToast from '@hooks/useToast'
import HeartsBubbleIcon from '@public/icons/chat-bubble-heart.svg'
import EnvelopeIcon from '@public/icons/envelope.svg'
import ExcitedCheckIcon from '@public/icons/excited-check.svg'
import PathsIcon from '@public/icons/paths.svg'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input'

import { verifyUser } from '@lib/api'
import { login, signUp } from '@lib/auth0'
import googleTagManager from '@lib/gtm'
import { submitNewCreatorLeadForm } from '@lib/hubspot'

import { ToastTypes } from '@models/toast'

import { TextField, TextPasswordField, PasswordRegExp } from '@components/forms'
import { PhoneField } from '@components/forms/PhoneField'
import { PageLayout } from '@components/layout/PageLayout'
import { StyledBaseContainer } from '@components/layout/PageLayout/styles'
import { SvgLoader } from '@components/shared/SvgLoader'
import { StyledLeftSideBoxWrapper, StyledRightSideBoxWrapper } from '@components/shared/styles'

import { ForgotPasswordComponent } from './ForgotPasswordComponent'
import { VerificationComponent } from './VerificationComponent'
import { messages } from './messages'
import {
  StyledAccessForm,
  StyledSubmitButton,
  StyledActions,
  StyledAccessWrapper,
  StyledSwitchAccess,
  StyledSignupDescriptionStepsWrapper,
  StyledSignupDescriptionStepWrapper,
  StyledBoxCard,
} from './styles'

export enum AccessMode {
  SignUp,
  SignIn,
  ForgotPassword,
  VerifyAccount,
}

export interface IAccessPageProps {
  defaultAccessMode: AccessMode
}

export interface IAccessFormData {
  email: string
  password: string
  first: string
  last: string
  phone: string
}

export const AccessPage: React.FC<IAccessPageProps> = ({ defaultAccessMode }) => {
  const router = useRouter()
  const intl = useIntl()
  const { addToast } = useToast()
  const [accessMode, setAccessMode] = useState(defaultAccessMode)
  // const [agreedTerms, setAgreedTerms] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [verifyEmail, setVerifyEmail] = useState<string>('')

  const { control, handleSubmit } = useForm({
    mode: 'onTouched',
    defaultValues: { email: '', first: '', last: '', phone: '', password: '' },
  })

  function showErrorToast(message: string) {
    addToast({
      message: message,
      type: ToastTypes.ERROR,
      noAutoClose: true,
    })
  }

  function signUpHandler(formData: IAccessFormData) {
    return new Promise<void>((resolve) => {
      googleTagManager.onMarketplaceSignup(
        formData.email,
        formData.first,
        formData.last,
        formData.phone
      )
      signUp(formData.email, formData.first, formData.last, formData.phone)
        .then(() => {
          submitNewCreatorLeadForm(
            {
              email: formData.email,
              first_name: formData.first,
              last_name: formData.last,
              phone_number: formData.phone,
            },
            router.query
          )
          setVerifyEmail(formData.email)
          setAccessMode(AccessMode.VerifyAccount)
        })
        .catch((error) => {
          showErrorToast(error?.description ?? error)
        })
        .finally(() => {
          resolve()
        })
    })
  }

  function signInHandler(formData: IAccessFormData) {
    return new Promise<void>((resolve) => {
      if (showPassword) {
        login(formData.email, formData.password)
          .catch((error) => {
            showErrorToast(error?.description ?? error)
          })
          .finally(() => {
            resolve()
          })
      } else {
        verifyUser(formData.email)
          .then((res) => {
            if (!res.userExists) {
              showErrorToast('User does not exist')
            } else if (res.hasPassword) {
              setShowPassword(true)
            } else {
              setVerifyEmail(formData.email)
              setAccessMode(AccessMode.VerifyAccount)
            }
          })
          .finally(() => resolve())
      }
    })
  }

  function onFormSubmit(formData: IAccessFormData, event?: React.BaseSyntheticEvent) {
    setIsSubmitted(true)
    event?.preventDefault()
    const formSubmitHandler = accessMode === AccessMode.SignUp ? signUpHandler : signInHandler
    formSubmitHandler(formData).then(() => {
      setIsSubmitted(false)
    })
  }

  function switchAccess() {
    setAccessMode(accessMode === AccessMode.SignIn ? AccessMode.SignUp : AccessMode.SignIn)
  }

  const getSubmitMessage = () => {
    switch (accessMode) {
      case AccessMode.SignIn:
        return isSubmitted ? messages.signinInButtonText : messages.signInButtonText
      case AccessMode.SignUp:
        return isSubmitted ? messages.signingUpButtonText : messages.signUpButtonText
      default:
        return messages.signInButtonText
    }
  }

  const renderFormBox = () => (
    <StyledBoxCard isAlternate={accessMode === AccessMode.SignUp}>
      {accessMode === AccessMode.VerifyAccount && (
        <VerificationComponent
          goBack={() => setAccessMode(AccessMode.SignIn)}
          sendEmailTo={verifyEmail}
        />
      )}
      {accessMode === AccessMode.ForgotPassword && (
        <ForgotPasswordComponent setAccessMode={setAccessMode} />
      )}
      {(accessMode === AccessMode.SignIn || accessMode === AccessMode.SignUp) && (
        <StyledAccessForm onSubmit={handleSubmit(onFormSubmit)} name="marketplace-access-form">
          {accessMode === AccessMode.SignUp && (
            <>
              <Controller
                control={control}
                name="first"
                rules={{
                  required: intl.formatMessage(messages.fieldRequiredError),
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      label={intl.formatMessage(messages.firstLabel)}
                      type="text"
                      id="first"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      error={fieldState?.error?.message}
                      required
                    />
                  )
                }}
              />

              <Controller
                control={control}
                name="last"
                rules={{
                  required: intl.formatMessage(messages.fieldRequiredError),
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      label={intl.formatMessage(messages.lastLabel)}
                      type="text"
                      id="last"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      error={fieldState?.error?.message}
                      required
                    />
                  )
                }}
              />

              <Controller
                control={control}
                name="phone"
                rules={{
                  required: intl.formatMessage(messages.fieldRequiredError),
                  validate: (value) =>
                    isValidPhoneNumber(value) || intl.formatMessage(messages.invalidPhoneError),
                }}
                render={({ field, fieldState }) => {
                  return (
                    <PhoneField
                      label={intl.formatMessage(messages.phoneLabel)}
                      id="phone"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      error={fieldState?.error?.message}
                      hasShadowCountryCode={false}
                      required
                    />
                  )
                }}
              />
            </>
          )}

          <Controller
            control={control}
            name="email"
            rules={{
              required: intl.formatMessage(messages.fieldRequiredError),
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: intl.formatMessage(messages.invalidMailError),
              },
            }}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  label={intl.formatMessage(messages.emailLabel)}
                  type="email"
                  id="email"
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  placeholder="your_name@email.com"
                  icon={EnvelopeIcon}
                  error={fieldState?.error?.message}
                  required
                />
              )
            }}
          />

          {accessMode === AccessMode.SignIn && showPassword && (
            <Controller
              control={control}
              name="password"
              rules={{
                required: intl.formatMessage(messages.fieldRequiredError),
                minLength: {
                  value: 8,
                  message: intl.formatMessage(messages.invalidPasswordError),
                },
                validate: (v) =>
                  PasswordRegExp.test(v) || intl.formatMessage(messages.invalidPasswordError),
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextPasswordField
                    label={intl.formatMessage(messages.signInPasswordLabel)}
                    id="password"
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    placeholder={intl.formatMessage(messages.signInPasswordPlaceholder)}
                    error={fieldState?.error?.message}
                    required
                  />
                )
              }}
            />
          )}

          {/* {accessMode === AccessMode.SignUp && (
            <CheckboxGroup
              noForm
              control={control as unknown as Control<Record<string, string>>}
              name="checkout-pre-requisit"
              variant="lg"
              onClick={(e) => {
                setAgreedTerms(e?.target?.checked)
              }}
              items={[
                {
                  label: intl.formatMessage(messages.agreeCheckboxText, {
                    a: (msg) => (
                      <a href="https://gembah.com/legal" target="_blank" rel="noopener noreferrer">
                        {msg}
                      </a>
                    ),
                  }) as string,
                  key: 'agree',
                },
              ]}
            />
          )} */}

          {accessMode === AccessMode.SignIn && showPassword && (
            <a onClick={() => setAccessMode(AccessMode.ForgotPassword)}>Forgot password?</a>
          )}

          <StyledActions isSignUp={accessMode === AccessMode.SignUp}>
            <StyledSubmitButton type="submit" secondary disabled={isSubmitted}>
              {intl.formatMessage(getSubmitMessage())}
            </StyledSubmitButton>

            <StyledSwitchAccess>
              {intl.formatMessage(
                accessMode === AccessMode.SignIn
                  ? messages.dontHaveAccountText
                  : messages.alreadyHaveAccountText
              )}
              <a onClick={switchAccess}>
                {intl.formatMessage(
                  accessMode === AccessMode.SignIn ? messages.signUp : messages.signIn
                )}
              </a>
            </StyledSwitchAccess>
          </StyledActions>
        </StyledAccessForm>
      )}
    </StyledBoxCard>
  )

  return (
    <PageLayout
      title={intl.formatMessage(messages.pageTitle, {
        access:
          accessMode === AccessMode.ForgotPassword
            ? intl.formatMessage(messages.forgotPassword)
            : accessMode === AccessMode.SignIn
            ? intl.formatMessage(messages.signIn)
            : intl.formatMessage(messages.signUp),
      })}
      hasAlternateHeader
      hasBreadcrumbs={false}
      hasFooter={false}
    >
      <StyledAccessWrapper hasSignupFormVisible={accessMode === AccessMode.SignUp}>
        {accessMode === AccessMode.SignUp && (
          <StyledLeftSideBoxWrapper>
            <StyledBaseContainer hasMaxWidth>
              <hr />
              <FormattedMessage {...messages.signUpTitle} tagName="h1" />
              <StyledSignupDescriptionStepsWrapper>
                <StyledSignupDescriptionStepWrapper>
                  <SvgLoader {...HeartsBubbleIcon} />
                  <div>
                    <FormattedMessage {...messages.stepTitle} values={{ no: 1 }} tagName="span" />
                    <FormattedMessage {...messages.stepOneDescription} tagName="span" />
                  </div>
                </StyledSignupDescriptionStepWrapper>

                <StyledSignupDescriptionStepWrapper>
                  <SvgLoader {...ExcitedCheckIcon} />
                  <div>
                    <FormattedMessage {...messages.stepTitle} values={{ no: 2 }} tagName="span" />
                    <FormattedMessage {...messages.stepTwoDescription} tagName="span" />
                  </div>
                </StyledSignupDescriptionStepWrapper>

                <StyledSignupDescriptionStepWrapper>
                  <SvgLoader {...PathsIcon} />
                  <div>
                    <FormattedMessage {...messages.stepTitle} values={{ no: 3 }} tagName="span" />
                    <FormattedMessage {...messages.stepThreeDescription} tagName="span" />
                  </div>
                </StyledSignupDescriptionStepWrapper>
              </StyledSignupDescriptionStepsWrapper>
              <hr />
            </StyledBaseContainer>
          </StyledLeftSideBoxWrapper>
        )}
        {accessMode === AccessMode.SignUp ? (
          <StyledRightSideBoxWrapper>{renderFormBox()}</StyledRightSideBoxWrapper>
        ) : (
          renderFormBox()
        )}
      </StyledAccessWrapper>
    </PageLayout>
  )
}
