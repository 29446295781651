import axios from 'axios'
import jwt from 'jsonwebtoken'

const SIGNIN_SECRET = process.env.SIGNIN_SECRET || ''

function generateToken(payload: object) {
  return jwt.sign(payload, SIGNIN_SECRET, { expiresIn: 10000 })
}

export function validateNewUser(email: string, phone: string) {
  return new Promise<boolean>((resolve) => {
    const token = generateToken({ email, phone })
    axios
      .request({
        method: 'POST',
        url: `https://${process.env.API_HOST}/profiles/validate/`,
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      })
      .then(() => {
        resolve(true)
      })
      .catch(() => {
        resolve(false)
      })
  })
}

export function createNewUser(
  email: string,
  phone: string,
  first_name: string,
  last_name: string,
  account_type: string,
  password?: string
) {
  return new Promise<void>((resolve, error) => {
    const token = generateToken({ email, password, phone, first_name, last_name, account_type })
    axios
      .request({
        method: 'POST',
        url: `https://${process.env.API_HOST}/profiles/create/`,
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      })
      .then(() => {
        resolve()
      })
      .catch((err) => {
        error(err)
      })
  })
}

export function verifyUser(email: string) {
  return new Promise<{ hasPassword: boolean; userExists: boolean }>((resolve, error) => {
    const token = generateToken({ email })
    axios
      .request<{ hasPassword: boolean; userExists: boolean }>({
        method: 'POST',
        url: `https://${process.env.API_HOST}/profiles/verify/`,
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        error(err)
      })
  })
}
