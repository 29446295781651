import * as Sentry from '@sentry/nextjs'
import { ParsedUrlQuery } from 'querystring'

import { IUser } from '@models/user'

import { JourneyStepData, JourneyStepIds } from '@components/journey/models'
import { calculateJourneyScore, formatJourneyAnswersForMutation } from '@components/journey/utils'

export interface IHubSpotUser {
  email: string
  first_name: string
  last_name: string
  phone_number: string
}

export interface IMarketingParams {
  utm_source: string
  utm_medium: string
  utm_campaign: string
  utm_content: string
  utm_term: string
  lead_source: string
  lead_source_detail: string
  gclid: string
  test_source: string
}

const submitForm = async (
  user: IHubSpotUser,
  score: number,
  data: Partial<Record<JourneyStepIds, JourneyStepData>>,
  isCompleted: boolean,
  marketing?: IMarketingParams
) => {
  try {
    const result = await fetch(`https://${process.env.MARKETPLACE_HOST}/api/hubspot`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user, data, isCompleted, marketing, score }),
    })

    if (!result.ok) {
      Sentry.captureException(result.status)
    }

    return ''
  } catch (e) {
    Sentry.captureException(e)
    return ''
  }
}

export const submitCreatorLeadForm = (
  user: IUser | undefined,
  data: Partial<Record<JourneyStepIds, JourneyStepData>>,
  isCompleted: boolean
) => {
  if (!user) {
    return
  }

  const journeyScore = isCompleted
    ? calculateJourneyScore(
        user.email,
        formatJourneyAnswersForMutation(data as Record<JourneyStepIds, JourneyStepData>)
      )
    : 0

  return submitForm(
    {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      phone_number: user.profile?.phone,
    },
    journeyScore,
    data,
    isCompleted
  )
}

export const submitNewCreatorLeadForm = (user: IHubSpotUser, query: ParsedUrlQuery) => {
  return submitForm(user, 0, {}, false, {
    utm_source: query.utm_source__c as string,
    utm_medium: query.utm_medium__c as string,
    utm_campaign: query.utm_campaign__c as string,
    utm_content: query.utm_content__c as string,
    utm_term: query.utm_term__c as string,
    lead_source: query.LeadSource as string,
    lead_source_detail: query.LeadSourceDetail as string,
    gclid: query.gclid as string,
    test_source: query.test_source as string,
  })
}
