import AccessMobileIllustration from '@public/illustrations/access-mobile.svg'
import AccessIllustration from '@public/illustrations/access.svg'
import { BREAKPOINTS, mediaQuery } from '@theme/responsive'
import styled, { css } from 'styled-components'

import { StyledButton } from '@components/shared'

export const StyledAccessWrapper = styled.div<{ hasSignupFormVisible: boolean }>`
  padding: ${({ hasSignupFormVisible }) => (hasSignupFormVisible ? '80px 0 0' : '100px 0')};
  height: ${({ hasSignupFormVisible }) => (hasSignupFormVisible ? 'auto' : '100%')};
  display: flex;
  background-attachment: fixed;
  background-position: center;
  background-repeat: round;
  ${({ hasSignupFormVisible, theme }) =>
    hasSignupFormVisible
      ? `background: ${theme.colors.white};`
      : `background-image: url(${AccessMobileIllustration.src});`}
  ${({ hasSignupFormVisible, theme }) =>
    mediaQuery(
      BREAKPOINTS.MEDIUM,
      `
      background-repeat: no-repeat;
      ${
        hasSignupFormVisible
          ? `background: ${theme.colors.white};`
          : `background-image: url(${AccessIllustration.src});`
      }
      background-size: 861.33px 340px;
    `
    )}

  ${mediaQuery(BREAKPOINTS.VERY_LARGE, `background-size: 1292px 510px;`)}

  ${({ hasSignupFormVisible }) =>
    hasSignupFormVisible &&
    `
    flex-direction: column;
    & > div {
      flex: 1;
    }
  `}

  ${({ hasSignupFormVisible }) =>
    hasSignupFormVisible &&
    mediaQuery(
      BREAKPOINTS.LARGE,
      `
        flex-direction: row;
        height: 100%;
        & > div {
          padding: 0 20px;
        }
      `
    )}
`

export const StyledSignupDescriptionStepsWrapper = styled.div`
  margin-top: 30px;
`

export const StyledSignupDescriptionStepWrapper = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 19px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.tealDollhouse};
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grayNes};
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  > div:first-child {
    > div {
      width: 80px;
      height: 80px;
      display: flex;
    }
    margin-right: 10px;
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > span:first-child {
      color: ${({ theme }) => theme.colors.purpleBopit};
    }
  }
`

export const StyledBoxCard = styled.div<{ isAlternate?: boolean }>`
  width: 350px;
  margin: 35px auto;
  box-shadow: 0px 0px 50px 20px rgba(149, 145, 142, 0.25);
  border-radius: 20px;

  height: auto;
  background: ${({ theme }) => theme.colors.white};
  padding: 20px;

  & a {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tealRobots} !important;

    &:hover {
      color: ${({ theme }) => theme.colors.tealDollhouse} !important;
    }
  }

  ${mediaQuery(
    BREAKPOINTS.LARGE,
    `
      margin: auto;
    `
  )}

  ${({ isAlternate }) =>
    mediaQuery(
      BREAKPOINTS.SMALL,
      `
      width: auto;
      max-width: 580px;
      padding: 40px;
      height: ${isAlternate ? ' min(780px, 80vh)' : 'auto'};
    `
    )}
`

export const StyledEmail = styled.p`
  color: ${({ theme }) => theme.colors.tealDollhouse} !important;
  font-style: italic !important;
`

const popupCss = css`
  display: flex;
  flex-direction: column;

  & > h2 {
    color: ${({ theme }) => theme.colors.tealDollhouse};
    font-family: ${({ theme }) => theme.fonts.merriweather};
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 65px;
    margin-bottom: 25px;
  }

  & > p {
    color: ${({ theme }) => theme.colors.tealRobots};
    font-family: ${({ theme }) => theme.fonts.lato};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 25px;

    ${mediaQuery(BREAKPOINTS.SMALL, `margin-bottom: 50px;`)}
  }
`

export const StyledVerifyEmailDiv = styled.div`
  ${popupCss}

  & > h2 {
    margin-bottom: 50px;
    ${mediaQuery(BREAKPOINTS.SMALL, `margin-bottom: 100px;`)}
  }

  & > div {
    display: flex;
    justify-content: flex-start;
  }
`

export const StyledPasswordResetForm = styled.form`
  ${popupCss}
`

export const StyledPasswordResetButtons = styled.div.attrs(
  (props: { emailSent: boolean }) => props
)`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.emailSent ? 'flex-end' : 'space-between')};
  width: 100%;
  margin-top: 25px;

  & > button {
    margin-top: 5px;
    padding: 0px 20px;
    width: 100%;
  }

  ${mediaQuery(
    BREAKPOINTS.SMALL,
    `
      flex-direction: row;
      align-items: center;
      margin-top: 95px;

      & > button {
        width: auto;
      }
    `
  )}
`

export const StyledPasswordResetButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.purpleBopit};
  background: ${({ theme }) => theme.colors.grayWii};
  border: 2px solid;
  border-color: ${({ theme }) => theme.colors.purpleMegatron};

  ${mediaQuery(BREAKPOINTS.SMALL, `margin-left: 5px;`)}
`

export const StyledGoBackButton = styled(StyledButton)`
  padding: 0px 20px;
  svg {
    transform: rotate(-90deg);
  }
`

export const StyledVerifyEmailButton = styled(StyledButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.purpleBopit};
  padding: 0px 20px;

  &:disabled {
    opacity: 0.5;
  }
`

export const StyledAccessForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${mediaQuery(BREAKPOINTS.SMALL, `min-width: min(500px, 98vw);`)}

  & > label {
    margin-bottom: 10px;
  }
`

export const StyledActions = styled.div<{ isSignUp?: boolean }>`
  display: flex;

  flex-direction: ${({ isSignUp }) => (isSignUp ? 'row-reverse' : 'column')};
  align-items: ${({ isSignUp }) => (isSignUp ? 'flex-end' : 'center')};
  justify-content: ${({ isSignUp }) => (isSignUp ? 'space-between' : 'center')};
  margin-top: ${({ isSignUp }) => (isSignUp ? 'auto' : '10px')};
  gap: ${({ isSignUp }) => (isSignUp ? '20px' : '0px')};
  flex-wrap: ${({ isSignUp }) => (isSignUp ? 'wrap' : 'none')};
`

export const StyledSwitchAccess = styled.div`
  margin-top: 10px;
  font-family: ${({ theme }) => theme.fonts.lato};
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.tealDollhouse};
`

export const StyledSubmitButton = styled(StyledButton)`
  padding: 0px 10px;

  ${mediaQuery(BREAKPOINTS.SMALL, `padding: 0px 20px;`)}

  ${mediaQuery(BREAKPOINTS.LARGE, `padding: 0px 60px;`)}
`
