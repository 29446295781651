import { JourneyStepData, JourneyStepIds, UpdateJourneyResponses } from '@components/journey/models'

export const formatJourneyAnswersForMutation = (
  answers: Record<JourneyStepIds, JourneyStepData>
): UpdateJourneyResponses[] => {
  return Object.entries(answers).map(([step, stepData]) => ({
    questionId: step as JourneyStepIds,
    question: stepData.question,
    answer: JSON.stringify(stepData.answer),
    score: (stepData.score ?? 0).toString(),
  }))
}

export const formatJourneyAnswersForWizard = (
  answers: UpdateJourneyResponses[]
): Record<JourneyStepIds, JourneyStepData> => {
  const nonNullAnswers = answers.filter((answerWrapper) => answerWrapper.answer !== 'null')

  return Object.fromEntries(
    nonNullAnswers.map(({ question, answer, score, questionId }) => [
      questionId,
      { question, score: parseFloat(score), answer: JSON.parse(answer as string) },
    ])
  ) as Record<JourneyStepIds, JourneyStepData>
}

const nonBusinessDomains = [
  'aol.com',
  'att.net',
  'bellsouth.net',
  'comcast.net',
  'cox.net',
  'gmail.com',
  'hotmail.com',
  'hotmail.co.uk',
  'icloud.com',
  'live.com',
  'mac.com',
  'mail.com',
  'msn.com',
  'outlook.com',
  'protonmail.com',
  'sbcglobal.net',
  'verizon.net',
  'yahoo.com',
  'yahoo.ca',
  'ymail.com',
]

// Check email against a list of common non-business domains
// If the domain isn't in that list, consider it a business email
export const isBusinessEmail = (email: string): boolean => {
  const domain = email?.replace(/^[^@]*@/, '')

  return !nonBusinessDomains.includes(domain)
}

export const calculateJourneyScore = (
  email = '',
  responses: UpdateJourneyResponses[] = []
): number => {
  const emailScore = isBusinessEmail(email) ? 1 : 0
  const surveyScore = responses.reduce((totalScore, response) => {
    return totalScore + parseFloat(response.score)
  }, 0)

  return emailScore + surveyScore
}
