import { useCachedUser } from '@hooks/useCachedUser'
import ChevronIcon from '@public/icons/chevron.svg'
import PlaneIcon from '@public/icons/plane.svg'
import React, { useEffect, useRef, useState } from 'react'

import { sendVerificationCode, verifyCode } from '@lib/auth0'

import { TextField } from '@components/forms'
import { StyledSvgLoader } from '@components/shared/SvgLoader/styles'

import {
  StyledGoBackButton,
  StyledVerifyEmailDiv,
  StyledVerifyEmailButton,
  StyledEmail,
} from './styles'

export const VerificationComponent: React.FC<{ sendEmailTo: string; goBack: () => void }> = ({
  sendEmailTo,
  goBack,
}) => {
  const { user } = useCachedUser()

  const [submittedEmail, setSubmittedEmail] = useState<string>(sendEmailTo ?? '')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [verificationCodeCooldown, setVerificationCodeCooldown] = useState<boolean>(false)
  const verificationCodeTimeout = useRef<NodeJS.Timeout>()

  function sendVerificationEmail(email: string, cooldown = false) {
    if (!email || (cooldown && verificationCodeCooldown)) {
      return
    }

    if (cooldown) setVerificationCodeCooldown(true)
    sendVerificationCode(email).finally(() => {
      if (cooldown) {
        if (verificationCodeTimeout.current) clearTimeout(verificationCodeTimeout.current)

        verificationCodeTimeout.current = setTimeout(() => {
          setVerificationCodeCooldown(false)
        }, 10000)
      }
    })
  }

  function onCodeChange(event?: React.ChangeEvent<HTMLInputElement>) {
    if (event) {
      event.preventDefault()
      if (event.target?.value?.length === 6) {
        verifyCode(submittedEmail, event.target.value).catch(() => {
          setErrorMessage(
            'The code you’ve entered is invalid or expired. Please try again or request a new email.'
          )
        })
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      setSubmittedEmail(user.email)
      sendVerificationEmail(user.email)
    }
  }, [user])

  useEffect(() => {
    if (sendEmailTo) {
      sendVerificationEmail(sendEmailTo)
    }
  }, [])

  return (
    <StyledVerifyEmailDiv>
      <h2>Verification Code</h2>
      <p>
        We've sent your verification code to the email below. Please enter your code to continue.
      </p>
      <StyledEmail>{submittedEmail}</StyledEmail>

      <TextField
        label="Verification Code"
        error={errorMessage}
        onChange={onCodeChange}
        maxLength={6}
        placeholder="6 digit code"
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
        <StyledGoBackButton
          disabled={verificationCodeCooldown}
          onClick={() => goBack()}
          primaryLight
        >
          <StyledSvgLoader {...ChevronIcon} width={16} />
          <span style={{ marginLeft: '15px' }}>go back</span>
        </StyledGoBackButton>
        <StyledVerifyEmailButton
          secondaryLight
          disabled={verificationCodeCooldown}
          onClick={() => sendVerificationEmail(submittedEmail, true)}
        >
          {verificationCodeCooldown ? 'email sent!' : 're-send email'}
          {!!errorMessage && <StyledSvgLoader {...PlaneIcon} width={48} />}
        </StyledVerifyEmailButton>
      </div>
    </StyledVerifyEmailDiv>
  )
}
