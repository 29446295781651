import React, { useState } from 'react'
import { Country } from 'react-phone-number-input'
import PhoneInput, { getCountries, getCountryCallingCode } from 'react-phone-number-input/input'
import en from 'react-phone-number-input/locale/en.json'

import { DropDown } from '../DropDown'
import { IPhoneFieldProps } from './models'
import { StyledCountryCode, StyledErrorLabel, StyledLabel, StyledPhoneInput } from './styles'

export const PhoneField: React.FC<IPhoneFieldProps> = ({
  label,
  value,
  icon,
  error,
  required,
  maxLength,
  onChange,
  hasShadowCountryCode = true,
  ...rest
}) => {
  const [country, setCountry] = useState('US')

  const countryOptions = getCountries()
    .map((c) => ({
      text: en[c],
      value: c,
    }))
    .sort((a, b) => (a?.text > b?.text ? 1 : -1))

  return (
    <StyledLabel icon={icon} hasError={!!error} required={required}>
      <span>{label}</span>

      <StyledPhoneInput
        hasError={!!error}
        country={country}
        hasShadowCountryCode={hasShadowCountryCode}
      >
        <DropDown allowEmpty defaultValue="US" items={countryOptions} onChange={setCountry} />

        {hasShadowCountryCode && (
          <StyledCountryCode>+{getCountryCallingCode(country as never)}</StyledCountryCode>
        )}

        <PhoneInput
          country={country as Country}
          value={value}
          required={required}
          onChange={onChange as unknown as (value: string) => void}
          {...rest}
        />
      </StyledPhoneInput>
      {error && <StyledErrorLabel>{error}</StyledErrorLabel>}
    </StyledLabel>
  )
}
