import * as Sentry from '@sentry/nextjs'
import auth0 from 'auth0-js'
import { v4 as uuid } from 'uuid'

import { createNewUser, validateNewUser } from './api'

const AUTH0_TENANT = process.env.AUTH0_TENANT || ''
const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN || ''
const AUTH0_ISSUER_BASE_URL = process.env.AUTH0_ISSUER_BASE_URL || ''
const AUTH0_CONNECTION = process.env.AUTH0_CONNECTION || 'Username-Password-Authentication'

const auth0Client = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: process.env.AUTH0_CLIENT_ID || '',
  overrides: {
    __tenant: AUTH0_TENANT,
    __token_issuer: AUTH0_ISSUER_BASE_URL,
  },
  scope: process.env.AUTH0_SCOPE,
  redirectUri: `https://${process.env.MARKETPLACE_HOST}/auth/callback`,
  responseType: 'id_token',
  state: uuid(),
})

export const ID_TOKEN_COOKIE_NAME = '88ab4b8f-0011-4407-ab28-9445f06866fd'
export const USER_ID_COOKIE_NAME = '1c4a8ed3-3965-4b6a-9077-59a2d7c55027'

export function login(email: string, password: string) {
  return new Promise<void>((resolve, error) => {
    auth0Client.login(
      { email: email, password: password, realm: AUTH0_CONNECTION, scope: process.env.AUTH0_SCOPE },
      (err) => {
        if (err) {
          Sentry.captureException(err)
          error(err)
        } else {
          resolve()
        }
      }
    )
  })
}

export function signInCallback() {
  return new Promise<auth0.Auth0DecodedHash>((resolve, error) => {
    auth0Client.parseHash({ hash: window.location.hash as string }, (err, result) => {
      if (err) {
        Sentry.captureException(err)
        error(err)
      } else if (result) {
        resolve(result)
      } else {
        Sentry.captureException('Unknown sign in error')
        error('Unknown error')
      }
    })
  })
}

export function crossOriginVerification() {
  auth0Client.crossOriginVerification()
}

export function logout() {
  auth0Client.logout({
    returnTo: `https://${process.env.MARKETPLACE_HOST}/login`,
  })
}

export function signUp(
  email: string,
  first: string,
  last: string,
  phone: string,
  password?: string
) {
  return new Promise<void>(async (resolve, error) => {
    const validSignup = await validateNewUser(email, phone)
    if (!validSignup) {
      error('This email or phone number has already been used')
      Sentry.captureException(`This email or phone number has already been used: ${email} ${phone}`)
      return
    }
    createNewUser(email, phone, first, last, 'creator', password)
      .then(() => {
        resolve()
      })
      .catch((err) => {
        Sentry.captureException(err)
        error(err)
      })
  })
}

export function sendVerificationCode(email: string) {
  return new Promise<void>((resolve, error) => {
    const passwordlessStartOptions = {
      client_secret: process.env.AUTH0_CLIENT_SECRET,
      connection: 'email',
      email: email,
      send: 'code',
      authParams: {
        redirectUri: `https://${process.env.MARKETPLACE_HOST}/auth/passwordless`,
      },
    }

    auth0Client.passwordlessStart(passwordlessStartOptions, (err) => {
      if (err) {
        Sentry.captureException(err)
        error(err)
      } else {
        resolve()
      }
    })
  })
}

export function verifyCode(email: string, code: string) {
  return new Promise<void>((resolve, error) => {
    auth0Client.passwordlessLogin(
      {
        connection: 'email',
        verificationCode: code,
        email: email,
        redirectUri: `https://${process.env.MARKETPLACE_HOST}/auth/passwordless`,
      },
      (err) => {
        if (err) {
          Sentry.captureException(err)
          error(err)
        } else {
          resolve()
        }
      }
    )
  })
}

export function resetPassword(email: string) {
  return new Promise<void>((resolve, error) => {
    auth0Client.changePassword(
      {
        connection: AUTH0_CONNECTION,
        email: email,
      },
      (err) => {
        if (err) {
          Sentry.captureException(err)
          error(err)
        } else {
          resolve()
        }
      }
    )
  })
}
