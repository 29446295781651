import { createMessages } from '@lib/createMessage'

const prefix = 'components.accesspage'

export const messages = createMessages({
  prefix,
  messages: {
    pageTitle: {
      defaultMessage: 'Gembah - {access}',
      description: 'Page title',
    },
    signIn: {
      defaultMessage: 'Sign in',
      description: 'Sign in',
    },
    signUp: {
      defaultMessage: 'Sign up',
      description: 'Sign up',
    },
    forgotPassword: {
      defaultMessage: 'Forgot Password',
      description: 'Forgot Password',
    },
    emailLabel: {
      defaultMessage: 'Email Address',
      description: 'Email label',
    },
    signUpPasswordLabel: {
      defaultMessage: 'Create Your Password',
      description: 'Password label',
    },
    signInPasswordLabel: {
      defaultMessage: 'Your Password',
      description: 'Password label',
    },
    firstLabel: {
      defaultMessage: 'First Name',
      description: 'First name label',
    },
    lastLabel: {
      defaultMessage: 'Last Name',
      description: 'Last name label',
    },
    phoneLabel: {
      defaultMessage: 'Phone Number',
      description: 'Phone label',
    },
    signUpPasswordPlaceholder: {
      defaultMessage: 'enter your super secret password',
      description: 'Password placeholder',
    },
    signInPasswordPlaceholder: {
      defaultMessage: 'your super secret password',
      description: 'Password placeholder',
    },
    invalidMailError: {
      defaultMessage: 'Please enter a valid email address',
      description: 'invalid email address error',
    },
    fieldRequiredError: {
      defaultMessage: 'This field is required',
      description: 'Field required error',
    },
    invalidPhoneError: {
      defaultMessage: 'Invalid phone number',
      description: 'invalid phone number error',
    },
    agreeCheckboxText: {
      defaultMessage: "I accept Gembah's <a>Terms & Conditions</a>",
      description: 'agree checkbox text',
    },
    signUpButtonText: {
      defaultMessage: 'next',
      description: 'sign up button text',
    },
    signingUpButtonText: {
      defaultMessage: 'getting ready...',
      description: 'signing up button text',
    },
    invalidPasswordError: {
      defaultMessage:
        'Minimum of 8 characters with combination of upper case, numbers, and smaller case.',
      description: 'invalid password error',
    },
    alreadyHaveAccountText: {
      defaultMessage: 'Already have an account? ',
      description: 'already have account text',
    },
    dontHaveAccountText: {
      defaultMessage: "Don't have an account? ",
      description: "don't have account text",
    },
    signInButtonText: {
      defaultMessage: 'continue',
      description: 'sign in button text',
    },
    signinInButtonText: {
      defaultMessage: 'signing in...',
      description: 'signing in button text',
    },
    forgotPasswordButtonText: {
      defaultMessage: 'send reset link',
      description: 'forgot password button text',
    },
    forgotPasswordSubmittedButtonText: {
      defaultMessage: 'resend link',
      description: 'forgot password submitted button text',
    },
    forgotPasswordTitle: {
      defaultMessage: 'Password Reset',
      description: 'Forgot password header title',
    },
    forgotPasswordSentTitle: {
      defaultMessage: 'Email Sent',
      description: 'Forgot password email sent header title',
    },
    forgotPasswordParagraph: {
      defaultMessage:
        'If an account with the below email address exists you will receive an email shortly.',
      description: 'Forgot password paragraph',
    },
    forgotPasswordSentParagraph: {
      defaultMessage: 'Enter your account email addres to receive a password reset link.',
      description: 'Forgot password sent paragraph',
    },
    forgotPasswordBackButton: {
      defaultMessage: 'take me back',
      description: 'Forgot password back button',
    },
    signUpTitle: {
      defaultMessage:
        'A global network of 1000’s of vetted factories, designers, and experts are just a few clicks away!',
      description: 'Sign up title',
    },
    stepTitle: {
      defaultMessage: 'Step {no}',
      description: 'step title',
    },
    stepOneDescription: {
      defaultMessage: 'Sign up and tell us about your product',
      description: 'step 1 description',
    },
    stepTwoDescription: {
      defaultMessage: 'Get matched with our network of providers',
      description: 'step 2 description',
    },
    stepThreeDescription: {
      defaultMessage: 'Start your fully guided journey',
      description: 'step 3 description',
    },
  },
})
